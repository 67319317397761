{

    class ReportService {

        static get $inject() {
            return ['ProjectService'];
        }

        constructor(ProjectService) {
            this.ProjectService = ProjectService;
        }

        isDifferentMPO() {
            const { project } = this.ProjectService;

            if (project && project.fiberGroups) {
                let mpoFibergroups = project.fiberGroups.filter(item => item.mpoPolarity === "None");

                return mpoFibergroups.length !== project.fiberGroups.length && mpoFibergroups.length !== 0;
            }

            return false;
        }

        filteredMediaType() {
            const { project } = this.ProjectService;

            return project && project.fiberGroups
                ? _.intersection(["SMF", "OS1", "OS2", "MMF", "OM1", "OM2", "OM3", "OM4", "OM5"],
                    project.fiberGroups
                        .map(item => item.fiberType)
                        .filter((item, index, array) => array.indexOf(item) === index))
                : [];
        }


        filteredResultsType() {
            const { project: {
                progress
            } } = this.ProjectService;

            const values = {
                completeOrPassed: "PassedOrComplete",
                failed: "Failed",
                incomplete: "Incomplete"
            };

            return Object.entries(progress).reduce((acc, [key, value]) => {
                if (values[key] && value > 0) {
                    acc.push(values[key]);
                }
                return acc;
               }, []);

        }

        filterAllowedReportOptions(outputAllowed, outputAllowedRequiredToolData, dataExists) {
            return Object.entries(outputAllowedRequiredToolData).reduce((acc, [key, value]) => {
                if (this.filterAllowed(value, dataExists)) {
                    acc.push(key);
                }

                return acc;
            }, []);
        }

        filterAllowed(requiredToolData, dataExists) {
            return !![...requiredToolData].reverse().find(
                element => element.filter(element => dataExists[element] === true).length
                    === element.length
            );
        }

        isMediaTypeAllowedBySelectedReport({allowedToolsToReport, allowedToolsForMediaType}) {
            return allowedToolsToReport
                .filter(element => _.isEqual(
                    new Set(element.map(element => element.toLowerCase())),
                    new Set(allowedToolsForMediaType.map(element => element.toLowerCase()))
                    )
                ).length > 0;
        }

        isResultTypeAllowed({resultType, fgs: fiberGroups}) {
            if (resultType === "Incomplete") {
                var showIncomplete = false ;

                showIncomplete = fiberGroups.some(function (fg, index, _arr) {
                    if (fg && fg.tools && fg.tools.length === 1) {
                        // Per Bhakti, "Single Tool" should have NO Incomplete option
                        return false ;
                    }
                    return fg.progress.incomplete > 0 ;
                });
                return showIncomplete ;
            }

            return true;
        }

        singleToolFilter({fiberGroups, toolType}) {
            return fiberGroups.filter(item => item && item.tools && item.tools.length === 1
                && item.tools[0].toolType.toLowerCase() === toolType.toLowerCase());
        }

        emptyInspectionFilter({fiberGroups}) {
            return this.singleToolFilter({
                fiberGroups,
                toolType: 'Inspection'
            }).filter(item => item.end1Connector.connectorType === "None"
                && item.end2Connector.connectorType === "None");
        }

        filterByMediaTypes({fiberGroups, mediaTypes }) {
            return fiberGroups.filter(item => {
                if (this.selectedAll(mediaTypes)) {
                    return true;
                }

                return mediaTypes.indexOf(item.fiberType) >= 0;
            });
        }

        filterBySelectedFiberGroups({fiberGroups, selectedFGNames}) {
            return fiberGroups.filter(item => {
                if (this.selectedAll(selectedFGNames)) {
                    return true;
                }

                return selectedFGNames.indexOf(item.id) >= 0;
            });
        }

        selectedAll(items) {
            return items.indexOf("All") >= 0;
        }

        getToolPerMediaType(fiberGroups, fiberType) {
            return fiberGroups
                .filter(element => element.fiberType === fiberType)
                .reduce((acc, element) => {
                    acc = _.uniq([...acc, ...this.getToolsFromFG(element)]);
                    return acc;
                }, []);

        }

        getToolsFromFG = fg => _.uniq(
            fg.tools.map(tool => tool.toolType)
        );
    }

    angular.module('aerosApp')
        .service('ReportService', ReportService);
}
